import React from 'react';
import {
  required,
  List,
  Datagrid,
  Create,
  SimpleForm,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
  Filter,
  TextInput,
  FunctionField,
  SelectInput,
    DateField,
} from 'react-admin';
import { PURCHASE_ORDER_TAGS } from '../../constants';
import RejectPurchaseOrderBtn from './RejectPurchaseOrderBtn';
const OrderFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User Id" source="userId" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="PhoneNumber" source="phoneNumber" alwaysOn />
    <SelectInput
      alwaysOn
      label="Status"
      source="status"
      choices={[
        { id: 'REQUESTED', name: 'REQUESTED' },
        { id: 'APPROVED', name: 'APPROVED' },
        { id: 'REJECTED', name: 'REJECTED' },
      ]}
    />
  </Filter>
);

const getButton = (status, id) => {
  if (status === 'APPROVED') {
    return <RejectPurchaseOrderBtn id={id}/>;
  }
  return '';
}

const ProductImageField = ({ record, source }) => {
  return (<img style={{ maxWidth: '100px' }}
               src={`${record.imageUrl}?width=100&height=100`}
               title={`#${record.title.en}`}
               alt={`#${record.id}`} />);
};

export const PurchaseOrderList = props => (
  <List {...props} filters={<OrderFilter/>} bulkActionButtons={false}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Product ID" source="items.0.productId" sortable={false} />
      <ReferenceField label="Product" source="items.0.productId" reference="products" sortable={false}>
        <ProductImageField label="Product" source="title" sortable={false} />
      </ReferenceField>
      <TextField label="Имэйл" source="user.email" sortable={false} />
      <TextField label="Утасны дугаар" source="user.phoneNumber" sortable={false} />
      <TextField label="Төлбөр" source="items.0.amount" sortable={false} />
      <TextField label="Статус" source="status" />
      <DateField label="Created" source="createdAt" showTime />
      <FunctionField label="Action" render={record => getButton(record.status, record.id)} sortable={false} />
    </Datagrid>
  </List>
);

const OptionRenderer = choice => (
    <span >
        <img
            title={choice?.id}
            alt=""
            src={`${choice.imageUrl}?width=100&height=100`}
            style={{ maxWidth: '50px', verticalAlign: 'middle', margin: '10px'}}
        />
        #{choice?.id}&nbsp;{choice?.title.en}
  </span>
);

const userOptionRenderer = choice => `${choice?.id}. ${choice?.email} ${choice?.phoneNumber}`;

export const PurchaseOrderCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Хэрэглэгч" source="userId" reference="users" validate={[required()]} fullWidth>
        <AutocompleteInput optionText={userOptionRenderer} />
      </ReferenceInput>
      <ReferenceInput label="NFT" source="productId"
                      reference="products"
                      validate={[ required() ]}
                      page={0}
                      perPage={200}
                      sort={{ field: 'id', order: 'DESC' }}
                      fullWidth>
        <SelectInput optionText={OptionRenderer} fullWidth />
      </ReferenceInput>
      <SelectInput
        style={{display: 'none'}}
        label="Төрөл"
        source="tag"
        defaultValue={PURCHASE_ORDER_TAGS[0].name}
        choices={PURCHASE_ORDER_TAGS}
      />
    </SimpleForm>
  </Create>
);
