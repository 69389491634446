export const COUNTRY_NAMES = [
  { id: 'USA', name: 'Америк' },
  { id: 'Germany', name: 'Герман' },
  { id: 'Australia', name: 'Австрали' },
  { id: 'South Korea', name: 'Солонгос' },
  { id: 'Japan', name: 'Япон' },
  { id: 'UK', name: 'Англи' },
  { id: 'China', name: 'Хятад' },
  { id: 'Russia', name: 'Орос' },
  { id: 'Taiwan', name: 'Тайвань' },
  { id: 'Canada', name: 'Канад' },
];

export const SUBSCRIPTION_TYPES = {
  FREE: '0 months',
  ONE: '1 months',
  TWO: '2 months',
  THREE: '3 months',
  FOUR: '4 months',
  FIVE: '5 months',
  SIX: '6 months',
  SEVEN: '7 months',
  EIGHT: '8 months',
  NINE: '9 months',
  TEN: '10 months',
  ELEVEN: '11 months',
  TWELVE: '12 months',
  FORTNIGHT: '14 days',
  WEEK: '7 days',
};

export const PLAN_CHOICES = [
  { id: SUBSCRIPTION_TYPES.ONE, name: '1 сар'},
  { id: SUBSCRIPTION_TYPES.TWO, name: '2 сар' },
  { id: SUBSCRIPTION_TYPES.THREE, name: '3 сар' },
  { id: SUBSCRIPTION_TYPES.FOUR, name: '4 сар' },
  { id: SUBSCRIPTION_TYPES.FIVE, name: '5 сар' },
  { id: SUBSCRIPTION_TYPES.SIX, name: '6 сар' },
  { id: SUBSCRIPTION_TYPES.SEVEN, name: '7 сар' },
  { id: SUBSCRIPTION_TYPES.EIGHT, name: '8 сар' },
  { id: SUBSCRIPTION_TYPES.NINE, name: '9 сар' },
  { id: SUBSCRIPTION_TYPES.TEN, name: '10 сар' },
  { id: SUBSCRIPTION_TYPES.ELEVEN, name: '11 сар' },
  { id: SUBSCRIPTION_TYPES.TWELVE, name: '12 сар' },
  { id: SUBSCRIPTION_TYPES.FORTNIGHT, name: '14 өдөр' },
  { id: SUBSCRIPTION_TYPES.WEEK, name: '7 өдөр' },
  { id: SUBSCRIPTION_TYPES.FREE, name: 'Үнэгүй' },
];

export const TAG_TYPES = [
  { id: 'COUNTRY', name: 'Улс'},
  { id: 'LANGUAGE', name: 'Хэл' },
  { id: 'LEVEL', name: 'Түвшин' },
  { id: 'VIDEO_CONTENT_TYPE', name: 'Видео контентын төрөл' },
];

export const SOCIAL_TYPES = [
  {id:'FB', name:'FB' },
  {id:'GOOGLE', name:'GOOGLE'},
  {id:'MANUAL', name:'MANUAL'},
  {id:'APPLE',name: 'APPLE'},
  {id:'ANONYMOUS', name:'ANONYMOUS'},
];

export const USER_TYPES = [
  { id: 'FREE', name: 'FREE' },
  { id: 'PAID', name: 'PAID' },
  { id: 'STAFF', name: 'STAFF' },
  { id: 'MENTOR', name: 'MENTOR' },
  { id: 'ECC', name: 'ECC' },
  { id: 'SPECIAL_GUEST', name: 'SPECIAL_GUEST' },
];

export const VERIFICATION_STATUS = [
  { id: 'PENDING', name: 'PENDING' },
  { id: 'REJECTED', name: 'REJECTED' },
  { id: 'VERIFIED', name: 'VERIFIED' },
];

export const CONTENT_STATUS = [
  { id: 'published', name: 'нийтлэсэн' },
  { id: 'draft', name: 'драфтласан' },
]

export const PURCHASE_ORDER_TAGS = [
  { id: 'TOMYO', name: 'TOMYO' },
  { id: 'SUPER_UP', name: 'SUPER_UP' },
];

export const VIDEO_TYPES = [
  { id: 'course', name: 'Course' },
  { id: 'alumniVoice', name: 'Alumni Voice' },
  { id: 'outliers', name: 'Outliers' },
  { id: 'worldly', name: 'Worldly' },
  { id: 'advices', name: 'Advices' }
]

export const LANGUAGE_LEVELS = [
  { id: 'A1', name: 'A1' },
  { id: 'A2', name: 'A2' },
  { id: 'B1', name: 'B1' },
  { id: 'B2', name: 'B2' },
  { id: 'C1', name: 'C1' },
  { id: 'C2', name: 'C2' }
]

export const AUCTION_TYPES = [
  { id: 'OPEN', name: 'OPEN' },
  { id: 'BUY_IT_NOW', name: 'BUY IT NOW' },
]

export const TIER_TYPES = [
  { id: 'gold', name: 'gold' },
  { id: 'platinum', name: 'platinum' },
  { id: 'purple_gold', name: 'purple_gold' },
]

export const CLAIM_STATUS = [
  { id: 'true', name: 'true' },
  { id: 'false', name: 'false' },
]