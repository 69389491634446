import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Filter,
  TextInput,
  ReferenceField,
  DateField,
  TextField,
  BooleanField,
  ExportButton,
  FunctionField,
  Pagination,
} from 'react-admin';
import WinnerChangePaidAtBtn from "./WinnerChangePaidAtBtn";

// function formatNumber(num) {
//   return num ? `${num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} MNT` : '';
// }

const UserInfoField = ({ record }) => {
  return `${record.id}. ${record.email} - ${record.phoneNumber}`;
};

const ProductImageField = ({ record, source }) => {
  return (
    <img style={{ maxWidth: '100px' }}
               src={`${record?.product?.imageUrl}?width=100&height=100`}
               title={`#${record?.product?.id}`}
               alt={`#${record?.product?.id}`} />
  );
};

const Action = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton />
    </Toolbar>
  );
};

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Auction Id" source="auctionId" alwaysOn />
    <TextInput label="Product Id" source="productId" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="PhoneNumber" source="phoneNumber" alwaysOn />
  </Filter>
);

const renderDiff = record => { return record?.bidPrice - record?.auction?.entryPrice || 0 }
const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const WinnerList = props => (
  <List {...props} filters={<TextFilter/>} actions={<Action props={props} />} pagination={<PostPagination />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Auction ID" source="auctionId" />
      <TextField label="Product ID" source="product.id" />
      <ProductImageField label="Product" title="id" />
      <TextField label="Product Title" source="product.title.en"/>
      <ReferenceField source="userId" reference="users">
        <UserInfoField label="User" title="id" />
      </ReferenceField>
      <TextField label="Дэнчин үнэ" source="auction.entryPrice" />
      <TextField label="Bid Amount" source="bidPrice" />
      <FunctionField label="Төлөх дүн" render={renderDiff} />
      <DateField label="Bid placed At" source="bidPlacedAt" showTime />
      <DateField label="Winner create" source="issuedAt" showTime />
      <DateField label="Төлбөр төлөх огноо" source="paymentDueDate" showTime />
      <BooleanField label="Is Paid" source="isPaid" />
      <WinnerChangePaidAtBtn label="Action" />
    </Datagrid>
  </List>
);