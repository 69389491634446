import React, { useState } from 'react';
import { useNotify, useDataProvider, Button, useRefresh } from 'react-admin';

const RejectPurchaseOrderBtn = ({ id }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    setLoading(true);
    dataProvider.patch(`purchaseOrders/${id}`, {})
      .catch((e) => {
        console.log(e)
        notify('Error: order not rejected', 'warning')
      })
      .finally(() => {
        setLoading(false);
        refresh();
      });
  };
  return (
    <>
      <Button onClick={handleClick} label="Буцаах" disabled={loading} />
    </>
  );
};

export default RejectPurchaseOrderBtn;
