import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Create,
  CreateButton,
  Edit,
  Filter,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
//  FunctionField,
  SelectInput,
  NumberInput,
  ChipField,
  required
} from 'react-admin';

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Нэр" source="name" alwaysOn />
  </Filter>
);


const Action = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

// const getStatus = (status) => {
//   if (status === 'draft') {
//     return "драфтласан";
//   }
//   return "нийтлэсэн";
// }

export const CategoryList = props => (
  <List {...props} filters={<TextFilter/>} actions={<Action props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Нэр" source="name" />
      <TextField label="Rank" source="rank" />
      <ChipField label="Статус" source="status" />
      <EditButton />
    </Datagrid>
  </List>
);

const CategoryTitle = ({ record }) => {
  const { name } = record;
  return <span>Category {record ? `"${name}"` : ''}</span>;
};

export const CategoryEdit = props => (
  <Edit title={<CategoryTitle />} {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput label="Нэр" source="name" validate={[ required() ]}/>
      <NumberInput label="Rank" source="rank" validate={[ required() ]} />
      <SelectInput
        label="статус"
        source="status"
        defaultValue="draft"
        validate={[required()]}
        choices={[
          { id: 'published', name: 'нийтлэсэн' },
          { id: 'draft', name: 'драфтласан' },
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const CategoryCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Нэр" source="name" validate={[ required() ]} />
      <NumberInput label="Rank" source="rank" validate={[ required() ]} />
      <SelectInput
        label="статус"
        source="status"
        defaultValue="draft"
        validate={[required()]}
        choices={[
          { id: 'published', name: 'нийтлэсэн' },
          { id: 'draft', name: 'драфтласан' },
        ]}
      />
    </SimpleForm>
  </Create>
);
