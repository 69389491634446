import React from 'react';
import { Admin, Resource } from 'react-admin';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import authProvider from './AuthProvider';
import './App.css';
import { UserList, UserEdit, UserCreate } from './components/user/Users';
import { MemberList, MemberEdit } from './components/memberNftOwners';
import { TagList, TagEdit, TagCreate } from './components/Tags';
import dtProvider from './utils/dataProvider';
import { UserCount } from './components/user/UserCount';
import { PaymentList } from './components/payment/Payment';
// import { TransactionList, TransactionCreate } from './components/payment/Transaction';
import { PaymentLogList } from './components/payment/PaymentLog';
import { NotificationList, NotificationCreate } from './components/Notification';
import { AdminUserList, AdminUserEdit, AdminUserCreate } from './components/user/AdminUser';
import { ROLES } from './constants/AdminUser';
// import { CouponList, CouponCreate, CouponEdit, BulkCouponCreate } from './components/Coupon';
import { ProductList, ProductCreate, ProductEdit } from './components/product/Product';
import { CategoryList, CategoryCreate, CategoryEdit } from './components/product/Category';
import Layout from './components/TomYoLayout';
import i18nProvider from './utils/i18n/i18nProvider';
import {
  ReviewList,
  ReviewEdit,
} from './components/Review';
import {AuctionCreate, AuctionEdit, AuctionList} from "./components/auction/Auction";
import {UserVerificationEdit, UserVerificationList} from "./components/user/UserVerications";
// import {
//   AverageReviewList,
// } from './components/AverageReview';
import { BidderList } from "./components/auction/Bidder";
import { BidList } from "./components/auction/Bids";
import { WinnerList } from "./components/auction/Winner";
import {PurchaseOrderCreate, PurchaseOrderList} from "./components/payment/PurchaseOrder";

function App() {
  return (
    <Admin layout={Layout} dataProvider={dtProvider} authProvider={authProvider}  i18nProvider={i18nProvider}>
      {permissions => [
        <Resource name="product" icon={ArrowForwardIosIcon} options={{ label: 'Product', isMenuParent: true }} />,
          (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.TEST_ADMIN) ||
            permissions.includes(ROLES.PRODUCTION_ADMIN) || permissions.includes(ROLES.MENTOR_ADMIN)) &&
            <Resource
              name="tags"
              options={{ label: 'Tags', menuParent: 'product' }}
              list={TagList}
              edit={TagEdit}
              create={TagCreate}
            />,
          (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.TEST_ADMIN) ||
            permissions.includes(ROLES.PRODUCTION_ADMIN) || permissions.includes(ROLES.MENTOR_ADMIN)) &&
            <Resource
              name="products"
              options={{ label: 'Product', menuParent: 'product' }}
              list={ProductList}
              edit={ProductEdit}
              create={ProductCreate}
            />,
          (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.TEST_ADMIN) ||
            permissions.includes(ROLES.PRODUCTION_ADMIN) || permissions.includes(ROLES.MENTOR_ADMIN)) &&
              <Resource
                name="categories"
                options={{ label: 'Category', menuParent: 'product' }}
                list={CategoryList}
                edit={CategoryEdit}
                create={CategoryCreate}
              />,

        (permissions.includes(ROLES.SUPER_ADMIN) ||
          permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
        <Resource name="auction" icon={ArrowForwardIosIcon} options={{ label: 'Auction', isMenuParent: true }} />,

        (permissions.includes(ROLES.SUPER_ADMIN) ||
          permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
        <Resource
          name="auctions"
          options={{ label: 'Auction', menuParent: 'auction' }}
          list={AuctionList}
          edit={AuctionEdit}
          create={AuctionCreate}
        />,
        (permissions.includes(ROLES.SUPER_ADMIN) ||
          permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
        <Resource
          name="bidders"
          options={{ label: 'Bidders', menuParent: '#'}}
          list={BidderList}
        />,
        (permissions.includes(ROLES.SUPER_ADMIN) ||
          permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
        <Resource
          name="bids"
          options={{ label: 'Bids', menuParent: '#'}}
          list={BidList}
        />,
        (permissions.includes(ROLES.SUPER_ADMIN) ||
          permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
        <Resource
          name="winners"
          options={{ label: 'Winners', menuParent: 'auction'}}
          list={WinnerList}
        />,
        (permissions.includes(ROLES.SUPER_ADMIN) ||
          permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
          <Resource name="user" icon={ArrowForwardIosIcon} options={{ label: 'Хэрэглэгч', isMenuParent: true }} />,
        (permissions.includes(ROLES.SUPER_ADMIN) ||
          permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
          <Resource
            name="users"
            options={{ label: 'Хэрэглэгч', menuParent: 'user' }}
            list={UserList}
            edit={UserEdit}
            create={UserCreate}
          />,
          (permissions.includes(ROLES.SUPER_ADMIN) ||
          permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&

          <Resource
            name="memberNftOwners"
            options={{ label: 'member list', menuParent: 'user' }}
            list={MemberList}
            edit={MemberEdit}
          />,

        (permissions.includes(ROLES.SUPER_ADMIN) ||
          permissions.includes(ROLES.MENTOR_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
        <Resource
          name="userVerifications"
          options={{ label: 'KYC', menuParent: 'user' }}
          list={UserVerificationList}
          edit={UserVerificationEdit}
        />,
        permissions.includes(ROLES.SUPER_ADMIN) &&
          <Resource
            name="Allusers"
            options={{ label: 'Хэрэглэгчийн тоо', menuParent: 'user' }}
            list={UserCount}
          />,

        (permissions.includes(ROLES.SALES_ADMIN) || permissions.includes(ROLES.SUPER_ADMIN)) &&
          <Resource
            name="notifications"
            options={{ label: 'SMS Илгээх', menuParent: 'user' }}
            list={NotificationList}
            create={NotificationCreate}
          />,
        permissions.includes('SUPER_ADMIN') &&
          <Resource
            name="adminUsers"
            options={{ label: 'Админ хэрэглэгч', menuParent: 'user' }}
            list={AdminUserList}
            edit={AdminUserEdit}
            create={AdminUserCreate}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
          <Resource name="sales" icon={ArrowForwardIosIcon} options={{ label: 'Sales', isMenuParent: true }} />,

        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
          <Resource
            name="payments"
            options={{ label: 'Төлбөрийн гүйлгээ', "menuParent":"sales" }}
            list={PaymentList}
          />,
        // (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
        //   <Resource
        //     name="bankTransactions"
        //     options={{ label: 'Банкны гүйлгээ', "menuParent":"sales" }}
        //     list={TransactionList}
        //     create={TransactionCreate}
        //   />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
          <Resource
            name="paymentLogs"
            options={{ label: 'Төлбөрийн төлөлтийн түүх', "menuParent":"sales" }}
            list={PaymentLogList}
          />,
        (permissions.includes(ROLES.SUPER_ADMIN) || permissions.includes(ROLES.SALES_ADMIN)) &&
        <Resource
            name="purchaseOrders"
            options={{ label: 'Захиалга',  "menuParent":"sales" }}
            list={PurchaseOrderList}
            create={PurchaseOrderCreate}
        />,
        // (permissions.includes(ROLES.SALES_ADMIN) || permissions.includes(ROLES.SUPER_ADMIN)) &&
        //   <Resource
        //     name="coupons"
        //     options={{ label: 'Купон', "menuParent":"sales" }}
        //     list={CouponList}
        //     edit={CouponEdit}
        //     create={CouponCreate}
        //   />,
        // (permissions.includes(ROLES.SALES_ADMIN) || permissions.includes(ROLES.SUPER_ADMIN)) &&
        //   <Resource
        //     name="bulk/coupons"
        //     options={{ label: 'Олон купон үүсгэх', "menuParent":"sales" }}
        //     create={BulkCouponCreate}
        //   />,
        // permissions.includes('SUPER_ADMIN') &&
        //   <Resource name="codeRelated" icon={ArrowForwardIosIcon} options={{label: 'Тохиргоо', isMenuParent:true}} />,
        // permissions.includes('SUPER_ADMIN') &&
        //   <Resource
        //     name="featureTargets"
        //     options={{ label: 'Pilot features', "menuParent":"codeRelated" }}
        //     icon={FiberNewIcon}
        //     list={FeatureTargetList}
        //     create={FeatureTargetCreate}
        //   />,
      permissions.includes('SUPER_ADMIN') &&
        <Resource name="review" icon={ArrowForwardIosIcon} options={{label: 'Сэтгэгдэл', isMenuParent:true}} />,
        permissions.includes(ROLES.SUPER_ADMIN) &&
        <Resource
            name="reviews"
            options={{ label: 'Сэтгэгдэл', menuParent: 'review' }}
            list={ReviewList}
            edit={ReviewEdit}
        />,
        // (permissions.includes(ROLES.SUPER_ADMIN)) &&
        // <Resource
        //     name="averageReviews"
        //     options={{ label: 'Дундаж үнэлгээ', menuParent: 'review' }}
        //     list={AverageReviewList}
        // />,

      ]}
    </Admin>
  );
}

export default App;
