import React, {useEffect, useState} from 'react';
import {useNotify, useDataProvider, useRefresh} from 'react-admin';
import Button from '@material-ui/core/Button';

const WinnerChangePaidAtBtn = ({record}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState('Төлбөр төлөөгүй');
  const paidAt = record?.paidAt;
  const handleClick = () => {
    if (window.confirm('Are you sure?')) {
      setLoading(true);
      dataProvider.patch(`winners/${record?.id}`, {data: {paidAt: !record?.paidAt}})
        .catch((e) => {
          console.log(e)
          notify('Error: Not saved!', 'warning')
        })
        .finally(() => {
          setLoading(false);
          refresh();
        });
    }
  };
  useEffect(() => {
    if (paidAt) {
      setLabel('Төлбөр төлөөгүй');
    } else {
      setLabel('Төлбөр төлсөн');
    }
  }, [paidAt])

  return (
    <>
      <Button color="primary" onClick={handleClick} label="Save" disabled={loading}>
        {label}
      </Button>
    </>
  );
};

export default WinnerChangePaidAtBtn;
