import React, { useState} from 'react';
import { useNotify, useDataProvider, useRefresh } from 'react-admin';
import Button from '@material-ui/core/Button';
const BidChangeWinnerBtn = ({ record }) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const handleClick = () => {
    if (window.confirm('Are you sure?')) {
      setLoading(true);
      dataProvider.patch(`bids/winner/${record.id}`, { data:{ winner: record.id } })
        .catch((e) => {
          console.log(e)
          notify('Error: Not saved!', 'warning')
        })
        .finally(() => {
          setLoading(false);
          refresh();
        });
    }
  };
  return (
    <>
      <Button color="primary" onClick={handleClick} label="Save" disabled={loading} >
        Make Winner
      </Button>
    </>
  );
};

export default BidChangeWinnerBtn;
