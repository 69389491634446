import React, {useEffect, useState} from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Create,
  CreateButton,
  Edit,
  Filter,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  FunctionField,
  SelectInput,
  required,
  ImageField,
  ImageInput,
  SelectArrayInput,
  Loading,
  ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  ReferenceField,
  Pagination,
  BooleanField,
} from 'react-admin';
import dataProvider from "../../utils/dataProvider";

const ProductImageField = ({ record, source }) => {
  return record ? (<img style={{ maxWidth: '100px' }} src={`${record.imageUrl}?width=100&height=100`} title={`#${record.id}`} alt={record.id} />) : '';
};

const fetchTagsData = () => {
  return dataProvider.getList('tags', {
    pagination:{
      page: 1, perPage: 100,
    },
    sort: {
      field: "id",
      order: "ASC",
    }
  }).then(items => {
    return items.data.map(item => {
      return {
        id: item.name,
        name: `${item?.name}`,
      }
    }).sort((a, b) => a.name - b.name);
  });
}

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Id" source="id" alwaysOn />
    <TextInput label="Auction Id" source="auctionId" alwaysOn />
    <TextInput label="Title" source="title" alwaysOn />
    <ReferenceInput label="Category" source="categoryId" reference="categories" alwaysOn >
      <SelectInput emptyValue={null} optionText="name" />
    </ReferenceInput>
  </Filter>
);


const Action = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

const getStatus = (status) => {
  if (status === 'draft') {
    return "драфтласан";
  }
  return "нийтлэсэн";
}

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const ProductList = props => (
  <List {...props} filters={<TextFilter/>} actions={<Action props={props} />}  pagination={<PostPagination />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <TextField label="Auction ID" source="auctionId" />
      <ProductImageField label="Product" source="imageUrl" title="id" />
      <ReferenceField source="categoryId" reference="categories">
        <TextField label="Category" source="name" title="id" />
      </ReferenceField>
      <TextField label="Нэр" source="title[en]" />
      <BooleanField label="Is Sold" source="isSold" />
      <FunctionField label="Статус" render={record => getStatus(record.status)} />
      <EditButton />
    </Datagrid>
  </List>
);

const ProductTitle = ({ record }) => {
  const { name } = record;
  return <span>Product {record ? `"${name}"` : ''}</span>;
};

export const ProductEdit = props => {
  // tags
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Promise.all([
      fetchTagsData().then(choices => {
        setTags(choices);
      }),
    ]).then(() => {
      setLoading(false);
    })
  }, [])
  if (loading) return <Loading />;
  return (
    <Edit title={<ProductTitle />} {...props}>
      <SimpleForm>
        <TextInput disabled source="id" />
        <ReferenceInput label="Category" source="categoryId" reference="categories">
          <SelectInput emptyValue={null} optionText="name" />
        </ReferenceInput>

        <ArrayInput fullWidth label="Нэр" source="title">
          <SimpleFormIterator disableRemove disableAdd>
            <FormDataConsumer>
              {({ formData, getSource, scopedFormData }) => {
                return (
                  <>
                    <TextInput label="Хэл" source={getSource('code')} disabled />
                    <TextInput label="title" source={getSource('title')} style={{minWidth: '450px'}} validate={[required()]}/>
                  </>
                );
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput fullWidth label="Дэлгэрэнгүй" source="description">
          <SimpleFormIterator disableRemove disableAdd>
            <TextInput label="Хэл" source="code" disabled />
            <TextInput label="Дэлгэрэнгүй" source="description" multiline fullWidth validate={[required()]}/>
          </SimpleFormIterator>
        </ArrayInput>

        <SelectArrayInput label="Tags" source="tags" choices={tags} fullWidth />
        <ImageField source="imageUrl" label="Одоогийн Зураг" />
        <ImageInput source="file" label="Зураг" accept="image/*" >
          <ImageField source="src" title="title" />
        </ImageInput>
        <SelectInput
          label="статус"
          source="status"
          defaultValue="draft"
          validate={[required()]}
          choices={[
            { id: 'published', name: 'нийтлэсэн' },
            { id: 'draft', name: 'драфтласан' },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
}

export const ProductCreate = props => {
  // tags
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Promise.all([
      fetchTagsData().then(choices => {
        setTags(choices);
      }),
    ]).then(() => {
      setLoading(false);
    })
  }, [])
  if (loading) return <Loading />;
  return (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Category" source="categoryId" reference="categories">
        <SelectInput optionText="name" />
      </ReferenceInput>

      <ArrayInput fullWidth label="Нэр" source="title" defaultValue={[
        { code: 'en', 'title': '' },
        { code: 'mn', 'title': '' }
      ]}>
        <SimpleFormIterator disableRemove disableAdd>
          <FormDataConsumer>
            {({ formData, getSource, scopedFormData }) => {
              return (
                <>
                  <TextInput label="Хэл" source={getSource('code')} disabled />
                  <TextInput label="title" source={getSource('title')} style={{minWidth: '450px'}} validate={[required()]}/>
                </>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput fullWidth label="Дэлгэрэнгүй" source="description" defaultValue={[
        { code: 'en', 'description': '' },
        { code: 'mn', 'description': '' }
      ]}>
        <SimpleFormIterator disableRemove disableAdd>
          <TextInput label="Хэл" source="code" disabled />
          <TextInput label="Дэлгэрэнгүй" source="description" multiline fullWidth validate={[required()]}/>
        </SimpleFormIterator>
      </ArrayInput>

      <SelectArrayInput label="Tags" source="tags" choices={tags} fullWidth />
      <ImageInput source="file" label="Зураг" accept="image/*" validate={[ required() ]}>
        <ImageField source="src" title="title" />
      </ImageInput>
      <SelectInput
        label="статус"
        source="status"
        defaultValue="draft"
        validate={[required()]}
        choices={[
          { id: 'published', name: 'нийтлэсэн' },
          { id: 'draft', name: 'драфтласан' },
        ]}
      />
    </SimpleForm>
  </Create>
)};
