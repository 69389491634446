import React, {useEffect, useState} from 'react';
import { useFormState } from 'react-final-form';
import Toolbar from '@material-ui/core/Toolbar';
import {
  ArrayInput,
  // BooleanInput,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  EditButton,
  Filter,
  FormDataConsumer,
  FunctionField,
  List,
  NumberInput,
  Pagination,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
  AutocompleteInput,
  minValue, ChipField,
} from 'react-admin';
import {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import GroupIcon from '@material-ui/icons/Group';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {AUCTION_TYPES} from "../../constants";

const LinkToBidders = ({record}) => {
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/bidders',
        search: `filter=${JSON.stringify({productId: record.productId})}`,
      }}
    >
      <GroupIcon style={{fontSize: 20}}/>&nbsp;{record?.nbBidders}
    </Button>
  ) : null;
};

const LinkToBids = ({record}) => {
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/bids',
        search: `filter=${JSON.stringify({auctionId: record.id})}`,
      }}
    >
      <AttachMoneyIcon style={{fontSize: 20}}/>&nbsp;{record?.nbBids}
    </Button>
  ) : null;
};

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const ProductImageField = ({record, source}) => {
  return (<img style={{maxWidth: '100px'}}
               src={`${record.imageUrl}?width=100&height=100`}
               title={`#${record.id}`}
               alt={`#${record.id}`}/>);
};

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ID" source="id" alwaysOn/>
    <TextInput label="Product Id" source="productId" alwaysOn/>
    <TextInput label="Product Title" source="productTitle" alwaysOn/>
    <SelectInput label="Type" source="type" choices={AUCTION_TYPES} alwaysOn/>
    <ReferenceInput label="Category" source="categoryId" reference="categories" alwaysOn>
      <SelectInput emptyValue={null} optionText="name"/>
    </ReferenceInput>
  </Filter>
);

const Action = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props

  return (
    <Toolbar>
      {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath}/>
    </Toolbar>
  );
};

// const getStatus = (status) => {
//   if (status === 'draft') {
//     return "драфтласан";
//   }
//   return "нийтлэсэн";
// }

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

export const AuctionList = props => (
  <List {...props} filters={<TextFilter/>} actions={<Action props={props}/>} pagination={<PostPagination/>}>
    <Datagrid optimized>
      <TextField label="ID" source="id"/>
      <TextField label="ProductId" source="productId"/>
      <ReferenceField source="productId" reference="products">
        <ProductImageField label="Product" source="imageUrl" title="id"/>
      </ReferenceField>
      <TextField source="type"/>
      <DateField source="startAt" showTime label="Open at"/>
      <DateField source="endAt" showTime/>
      <DateField source="closeAt" showTime/>
      <FunctionField label="Дэнчин үнэ" render={record => `${formatNumber(record.entryPrice)} MNT`}/>
      <FunctionField label="Анхны үнэ" render={record => `${formatNumber(record.initPrice)} MNT`}/>
      <FunctionField label="Current Price" render={record => `${formatNumber(record.currentPrice)} MNT`}/>
      <ChipField label="Статус" source="status" />
      <LinkToBidders label="Bidders"/>
      <LinkToBids label="Bids"/>
      <EditButton/>
    </Datagrid>
  </List>
);

const AuctionTitle = ({record}) => {
  return <span>Auction {record && `#${record.id}`}</span>;
};

const StepPriceInput = props => {
  const { values: {
    type
  }} = useFormState();
  const [style, setStyle] = useState({});
  const [validate, setValidate] = useState([]);
  useEffect(() => {
    if (type === 'OPEN') {
      setStyle({});
      setValidate([required(), minValue(1)]);
    }
    if (type === 'BUY_IT_NOW') {
      setStyle({display: 'none'});
      setValidate([]);
    }
  }, [type])
  return (
    <ArrayInput style={style} {...props}>
      <SimpleFormIterator>
        <TextInput label="Yнэ" validate={validate} />
      </SimpleFormIterator>
    </ArrayInput>
  );
};
const ProductOptionRenderer = choice => {
  const { record } = choice;
  return (
    <span>
      <img title={choice?.id}
           alt=""
           src={record?.imageUrl}
           style={{maxWidth: '50px', verticalAlign: 'middle', margin: '10px'}} />
      #{record?.id}&nbsp;{record?.title?.en}
    </span>
  )
};
const inputText = choice => `#${choice?.id} ${choice?.title?.en}`;

export const AuctionEdit = props => (
  <Edit title={<AuctionTitle/>} {...props}>
    <SimpleForm>
      <TextInput disabled source="id"/>
      {/*<BooleanInput label="Yндсэн аукцион" source="isMainAuction" defaultValue={false}/>*/}
      <ReferenceInput label="NFT" source="productId"
                      reference="products"
                      validate={[required()]}
                      page={0}
                      perPage={200}
                      sort={{field: 'id', order: 'DESC'}}
                      fullWidth>
        <AutocompleteInput
          fullWidth
          focusInputOnSuggestionClick={true}
          shouldRenderSuggestions={(val) => { return true }}
          optionText={<ProductOptionRenderer />}
          inputText={inputText}
          matchSuggestion={(filterValue, suggestion) => true}
        />
      </ReferenceInput>
      <SelectInput
        label="Төрөл"
        source="type"
        defaultValue="OPEN"
        validate={[required()]}
        choices={AUCTION_TYPES}
      />
      <FormDataConsumer>
        {({formData, scopedFormData, getSource, ...rest}) => {
          if (formData.type === 'OPEN') {
            return <>
              <DateTimeInput label="Дэнчин байршуулах сүүлийн хугацаа" source="entryCloseAt" fullWidth />
              <DateTimeInput label="Эхлэх огноо" source="startAt" validate={[required()]}/>
              <DateTimeInput label="Дуусах огноо" source="endAt" validate={[required()]}/>
              <DateTimeInput label="Хаах огноо" source="closeAt" validate={[required()]}/>
              <NumberInput label="Дэнчин байршуулах үнэ" source="entryPrice" validate={[ required() ]} fullWidth />
              <NumberInput label="Анхны үнэ" source="initPrice" validate={[ required() ]} fullWidth />
            </>
          }

          if (formData.type === 'BUY_IT_NOW') {
            return <>
              <DateTimeInput label="Эхлэх огноо" source="startAt" validate={[required()]}/>
              <DateTimeInput label="Хаах огноо" source="closeAt" />
              <NumberInput label="Зарах үнэ" source="initPrice" validate={[ required() ]} fullWidth />
            </>
          }
        }}
      </FormDataConsumer>
      <StepPriceInput label="Yнэ өсгөх боломжууд" source="stepPrice" defaultValue={['']} />
      <SelectInput
        label="статус"
        source="status"
        defaultValue="draft"
        validate={[required()]}
        choices={[
          {id: 'published', name: 'нийтлэсэн'},
          {id: 'draft', name: 'драфтласан'},
        ]}
      />
    </SimpleForm>
  </Edit>
);

export const AuctionCreate = props => {
  return (
    <Create {...props} title="Аукцион үүсгэх">
      <SimpleForm>
        {/*<BooleanInput label="Yндсэн аукцион" source="isMainAuction" defaultValue={false}/>*/}
        <ReferenceInput label="NFT" source="productId"
                        reference="products"
                        validate={[required()]}
                        page={0}
                        perPage={200}
                        sort={{field: 'id', order: 'DESC'}}
                        fullWidth>
          <AutocompleteInput
            fullWidth
            focusInputOnSuggestionClick={true}
            shouldRenderSuggestions={(val) => { return true }}
            optionText={<ProductOptionRenderer />}
            inputText={inputText}
            matchSuggestion={(filterValue, suggestion) => true}
          />
        </ReferenceInput>
        <SelectInput
          label="Төрөл"
          source="type"
          defaultValue="OPEN"
          validate={[required()]}
          choices={AUCTION_TYPES}
        />

        <FormDataConsumer>
          {({formData, scopedFormData, getSource, ...rest}) => {
            if (formData.type === 'OPEN') {
              return <>
                <DateTimeInput label="Дэнчин байршуулах сүүлийн хугацаа" source="entryCloseAt" fullWidth />
                <DateTimeInput label="Эхлэх огноо" source="startAt" validate={[required()]}/>
                <DateTimeInput label="Дуусах огноо" source="endAt" validate={[required()]}/>
                <DateTimeInput label="Хаах огноо" source="closeAt" validate={[required()]}/>
                <NumberInput label="Дэнчин байршуулах үнэ" source="entryPrice" validate={[ required() ]} fullWidth />
                <NumberInput label="Анхны үнэ" source="initPrice" validate={[ required() ]} fullWidth />
              </>
            }

            if (formData.type === 'BUY_IT_NOW') {
              return <>
                <NumberInput label="Зарах үнэ" source="initPrice" validate={[ required() ]} fullWidth />
                <DateTimeInput label="Эхлэх огноо" source="startAt" validate={[required()]}/>
                <DateTimeInput label="Хаах огноо" source="closeAt" />
              </>
            }
          }}
        </FormDataConsumer>
        <StepPriceInput label="Yнэ өсгөх боломжууд" source="stepPrice" defaultValue={[0]} />

        <SelectInput
          label="статус"
          source="status"
          defaultValue="draft"
          validate={[required()]}
          choices={[
            {id: 'published', name: 'нийтлэсэн'},
            {id: 'draft', name: 'драфтласан'},
          ]}
        />
      </SimpleForm>
    </Create>
  );
}
