import React, {useEffect, useState} from 'react';
import {useNotify, useDataProvider, useRefresh} from 'react-admin';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';

const BidderChangeRefundedAtBtn = ({record}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState('Буцаалт хийсэн');
  const handleClick = () => {
    if (window.confirm('Are you sure?')) {
      setLoading(true);
      dataProvider.patch(`bidders/${record.id}`, {data: {refundedAt: !record.refundedAt}})
        .catch((e) => {
          console.log(e)
          notify('Error: Not saved!', 'warning')
        })
        .finally(() => {
          setLoading(false);
          refresh();
        });
    }
  };
  useEffect(() => {
    if (record.refundedAt) {
      setLabel('Буцаалт хийгээгүй');
    } else {
      setLabel('Буцаалт хийсэн');
    }
  }, [record.refundedAt])

  return (
    <>
      <Button color="primary" onClick={handleClick} label="Save" disabled={loading}>
        <SaveIcon style={{fontSize: 20}}/>&nbsp;{label}
      </Button>
    </>
  );
};

export default BidderChangeRefundedAtBtn;
