import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Filter,
  TextInput,
  ReferenceField,
  DateField,
  TextField,
  FunctionField,
} from 'react-admin';
import BidChangeWinnerBtn from "./BidChangeWinnerBtn";
import TrueIcon from '@material-ui/icons/Done';
import FalseIcon from '@material-ui/icons/Clear';

// function formatNumber(num) {
//   return num ? `${num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} MNT` : '';
// }

const UserInfoField = ({ record }) => {
  return `${record.id}. ${record.email} - ${record.phoneNumber}`;
};

const Action = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </Toolbar>
  );
};

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="PhoneNumber" source="phoneNumber" alwaysOn />
  </Filter>
);

const isWinner = record => {
  if (record.winner) {
    return <TrueIcon />;
  } else {
    return <FalseIcon />;
  }
}

export const BidList = props => (
  <List {...props} filters={<TextFilter/>} actions={<Action props={props} />}>
    <Datagrid optimized>
      <TextField label="ID" source="id" />
      <ReferenceField source="userId" reference="users">
        <UserInfoField label="User" title="id" />
      </ReferenceField>
      <TextField label="Price" source="price" />
      <DateField label="Огноо" source="createdAt" showTime />
      <FunctionField label="Winner" render={isWinner}/>
      <BidChangeWinnerBtn label="Action" />
    </Datagrid>
  </List>
);