import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {VERIFICATION_STATUS} from '../../constants';
import {
  List,
  Edit,
  Datagrid,
  TextField,
  ImageField,
  EmailField,
  SimpleForm,
  TextInput,
  EditButton,
  Filter,
  SelectInput,
} from 'react-admin';

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="User id" source="userId" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="Phone Number" source="phoneNumber" alwaysOn />
    <SelectInput label="Status" source="status" choices={VERIFICATION_STATUS} alwaysOn />
  </Filter>
);

const UserAction = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </Toolbar>
  );
};

export const UserVerificationList = props => (
  <List {...props} filters={<TextFilter />} actions={<UserAction props={props} />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="user.firstName" />
      <EmailField source="user.email" />
      <TextField source="user.phoneNumber" />
      <TextField source="status" />
      <TextField source="createdAt" />
      <EditButton />
    </Datagrid>
  </List>
);

export const UserVerificationEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <TextField source="user.firstName" />
      <TextField source="user.phoneNumber" />
      <TextField source="user.email" />
      <ImageField source="idFront" title="Нүүр зураг" />
      <ImageField source="idBack" title="Арын зураг" />
      <ImageField source="selfie" title="Selfie" />
      <SelectInput label="status" source="status" choices={VERIFICATION_STATUS} />
    </SimpleForm>
  </Edit>
);