import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import {
  List,
  Datagrid,
  Filter,
  TextInput,
  FunctionField,
  ReferenceField,
  DateField,
  BooleanField,
  // TextField,
  ExportButton,
} from 'react-admin';
import BidderChangeRefundedAtBtn from "./BidderChangeRefundedAtBtn";

function formatNumber(num) {
  return num ? `${num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} MNT` : '';
}

const UserInfoField = ({ record }) => {
  return `${record.id}. ${record.email} - ${record.phoneNumber}`;
};

const Action = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton />
    </Toolbar>
  );
};

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" alwaysOn />
    <TextInput label="PhoneNumber" source="phoneNumber" alwaysOn />
  </Filter>
);

export const BidderList = props => (
  <List {...props} filters={<TextFilter/>} actions={<Action props={props} />}>
    <Datagrid optimized>
      {/*<TextField label="ID" source="id" />*/}
      <ReferenceField source="userId" reference="users">
        <UserInfoField label="User" title="id" />
      </ReferenceField>
      <DateField label="Дэнчин тавьсан" source="depositedAt" showTime />
      <FunctionField label="Дэнчин байршуулсан үнэ" render={record => `${formatNumber(record.depositedAmount)}`} />
      {/*<TextField label="Банк" source="user.account.bankName" />*/}
      {/*<TextField label="Дансны дугаар" source="user.account.accountNo" />*/}
      <BooleanField label="Дэнчин буцаасан"  source="isRefunded" />
      <BidderChangeRefundedAtBtn label="Action" />
    </Datagrid>
  </List>
);