import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { CreateButton } from 'react-admin';
// import { SOCIAL_TYPES, USER_TYPES } from '../../constants';
import {TIER_TYPES} from "../constants";
import {CLAIM_STATUS} from "../constants";
import {
  List,
  Edit,
  Datagrid,
  TextField,
  EmailField,
  SimpleForm,
  TextInput,
  EditButton,
  Filter,
  SelectInput,
  required,
} from 'react-admin';

const TextFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Id" source="id" />
    <TextInput label="email" source="email" />
    <TextInput label="address" source="address" />
    <TextInput label="tier" source="tier" />
    <TextInput label="claimed" source="claimed" />
  </Filter>
);

const MemberAction = props => {
  const {
    filters,
    showFilter,
    resource,
    displayedFilters,
    filterValues,
    basePath,
  } = props

  return (
    <Toolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
    </Toolbar>
  );
};

export const MemberList = props => (
  <List {...props} filters={<TextFilter />} actions={<MemberAction props={props} />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <EmailField source="email" />
      <TextField source="address" />
      <TextField source="tier" />
      <TextField source="claimed" />
      <EditButton />
    </Datagrid>
  </List>
);

export const MemberEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" />
      <SelectInput
        label="tier"
        source="tier"
        validate={[required()]}
        choices={TIER_TYPES}
      />
      <TextInput source="email" />
      <SelectInput
        label="claimed"
        source="claimed"
        validate={[required()]}
        choices={CLAIM_STATUS}
      />
    </SimpleForm>
  </Edit>
);
